<template>
  <div class="px-3">
    <v-overlay v-model="loading">
      <v-progress-circular
        indeterminate
        size="40"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-row class="white rounded-xl px-5 font-weight-bold mb-2 text-left">
      <v-col cols="2">
        <div>Label</div>
      </v-col>
      <v-col cols="5">
        <div>Link URL</div>
      </v-col>
      <v-col cols="3">
        <div>Number of Recordings</div>
      </v-col>
      <v-col cols="2">
        <div>Actions</div>
      </v-col>
    </v-row>
    <div class="mt-6" v-for="(record, index) in recordings" :key="index">
      <v-row
        class="white rounded-xl px-5 font-weight-bold mb-2 text-left text-12"
      >
        <v-col cols="2" align-self="center">
          <a
            href="javascript:void(0)"
            style="cursor: text"
            class="text-decoration-none"
          >
            {{ record.name }}
          </a>
        </v-col>
        <v-col cols="5" align-self="center">
          <div>{{ record.record_link }}</div>
        </v-col>
        <v-col cols="3" align-self="center">
          {{ record.recordings_count }}
        </v-col>
        <v-col
          cols="2"
          class="px-0 d-flex justify-space-between"
          align-self="center"
        >
          <div>
            <v-icon
              @click="copyLink(record.id)"
              color="teal"
              class="rounded-xl pa-1 ml-2 grey lighten-3"
              title="copy"
            >
              mdi-link-variant
            </v-icon>
            <v-text-field
              style="opacity: 0; position: absolute"
              :value="record.record_link"
              :id="record.id"
            ></v-text-field>
            <v-icon
              color="error"
              class="rounded-xl pa-1 ml-2"
              style="background: #ffe6e6"
              @click="confirmDelete(record)"
              title="Delete"
            >
              mdi-trash-can-outline
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="!recordings.length">No record found!</div>
    <v-pagination
      v-model="pagination.page"
      :length="pagination.total"
      :total-visible="7"
      @input="nextPage()"
      v-if="
        $router['name'] !== 'Dashboard' &&
        recordings.length &&
        pagination.total > 1
      "
    ></v-pagination>
    <v-dialog v-model="dialog" max-width="400">
      <v-card class="pa-4 rounded-xl" flat>
        <v-toolbar class="error--text" flat>
          <v-spacer></v-spacer>
          <span class="text-h6">Confirm Delete!!!</span>
          <v-spacer></v-spacer>
          <v-icon color="error" size="18" @click="closeDialog">
            mdi-close
          </v-icon>
        </v-toolbar>
        <v-card-text class="error--text">
          If you delete this label, All your recorded and received videos
          associated with this label will be lost.
        </v-card-text>
        <div>
          <strong class="error--text">To continue, Type DELETE</strong>
          <v-text-field
            v-model="confirm_delete"
            solo
            dense
            flat
            rounded
            background-color="secondary"
          ></v-text-field>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error rounded-xl px-10"
            @click="deleteRequestedLink"
            large
            depressed
            :disabled="disabledBtn"
          >
            DELETE
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ResponseHelper from "../utils/ResponseHelper";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "requestedLinks",
  data() {
    return {
      recordings: [],
      toast: new ResponseHelper(),
      pagination: {
        page: 1,
        total: 1,
      },
      loading: false,
      search: "",
      timerId: null,
      timer: 0,
      dialog: false,
      confirm_delete: null,
    };
  },
  created() {
    this.requestedLinks();
    this.$root.$on("search", (data) => {
      this.timer = 1;
      this.timerId = setTimeout(() => {
        if (this.timer > 0) {
          this.search = data;
          this.pagination.page = 1;
          this.requestedLinks();
        }
        this.timer = 0;
      }, 2000);
    });
  },
  methods: {
    ...mapMutations("recordings", ["SHOW_DETAILS"]),
    ...mapActions("recordings", ["requested_links", "delete_requested_link"]),

    async requestedLinks(page) {
      if (!page) {
        page = "?page=" + this.pagination.page + "&search=" + this.search;
      }
      this.loading = true;
      try {
        const response = await this.requested_links(page);
        this.recordings = response.data.data;
        this.pagination.page = response.data["current_page"];
        this.pagination.total = response.data["last_page"];
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    showDetail(item) {
      this.SHOW_DETAILS(item);
      this.$router.push({
        name: "MyRecordingDetails",
        params: { id: item.id },
      });
    },
    nextPage() {
      let page = "?page=" + this.pagination.page + "&search=" + this.search;
      this.requestedLinks(page);
    },
    async confirmDelete(record) {
      this.record = record;
      this.dialog = true;
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.confirm_delete = null;
      this.dialog = false;
    },
    async deleteRequestedLink() {
      this.closeDialog();
      this.loading = true;
      try {
        const response = await this.delete_requested_link(this.record.id);
        this.loading = false;
        this.nextPage();
        this.toast.sendSuccess(response);
        await this.$parent.getDonutSeries();
        this.$root.$emit("reload-all-recordings");
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    copyLink(id) {
      let link = document.getElementById(id);
      link.select();
      document.execCommand("copy");
      this.$toast.success("Link copied to clipboard");
    },
  },
  computed: {
    disabledBtn() {
      return this.confirm_delete !== "DELETE";
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped></style>
