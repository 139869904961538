<template>
  <div>
    <v-overlay v-model="loading">
      <v-progress-circular
        indeterminate
        size="40"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-container class="tab-container">
      <v-tabs v-model="tab" fixed>
        <v-tab class="custom-tab" @click="reloadComponent(1)">Recordings</v-tab>
        <v-tab class="custom-tab" @click="reloadComponent(2)">Archive</v-tab>
        <v-tab class="custom-tab" @click="reloadComponent(3)"
          >Recycle Bin</v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tab" class="tabs-content">
        <v-tab-item :key="reloadKey + '1'" class="tab-item">
          <v-card :elevation="0" class="tabs-item-card">
            <!-- folder toolbar -->
            <MyFolders :key="reloadKey" />
            <!-- My Recordings -->
            <div class="mt-4">
              <v-toolbar flat class="transparent font-weight-bold ml-n4">
                My Recordings
              </v-toolbar>
              <dashboard-my-recordings></dashboard-my-recordings>
            </div>
            <!-- Received Recordings -->
            <div class="mt-4">
              <v-toolbar flat class="transparent font-weight-bold ml-n4">
                Received Recordings
              </v-toolbar>
              <dashboard-received-recordings></dashboard-received-recordings>
            </div>
            <!-- Requested Link -->
            <div class="mt-4">
              <v-toolbar flat class="transparent font-weight-bold ml-n4">
                Requested Links
              </v-toolbar>
              <pending-requests></pending-requests>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="reloadKey + '2'">
          <v-card :elevation="0" class="tabs-item-card">
            <!-- archives folders -->
            <MyArchives :key="reloadKey" />
            <!-- Archived Recordings -->
            <div class="mt-4">
              <v-toolbar flat class="transparent font-weight-bold ml-n4">
                My Recordings
              </v-toolbar>
              <dashboard-archive-recordings></dashboard-archive-recordings>
            </div>
            <!-- Archive Received Recordings -->
            <div class="mt-4">
              <v-toolbar flat class="transparent font-weight-bold ml-n4">
                Received Recordings
              </v-toolbar>
              <dashboard-received-archive-recordings></dashboard-received-archive-recordings>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="reloadKey + '3'">
          <v-card :elevation="0" class="tabs-item-card">
            <MyTrashes :key="reloadKey" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import ResponseHelper from "../utils/ResponseHelper";
import MyFolders from "./MyFolders.vue";
import MyArchives from "./MyArchives.vue";
import MyTrashes from "./MyTrashes.vue";
import PendingRequests from "./RequestedLink.vue";
import DashboardMyRecordings from "./DashboardMyRecordings.vue";
import DashboardArchiveRecordings from "./DashboardArchiveRecordings.vue";
import DashboardReceivedRecordings from "./DashboardReceivedRecordings.vue";
import DashboardReceivedArchiveRecordings from "./DashboardReceivedArchiveRecordings.vue";
export default {
  name: "MyLibrary",
  components: {
    MyFolders,
    MyArchives,
    MyTrashes,
    PendingRequests,
    DashboardMyRecordings,
    DashboardArchiveRecordings,
    DashboardReceivedRecordings,
    DashboardReceivedArchiveRecordings,
  },
  data() {
    return {
      toast: new ResponseHelper(),
      tab: null,
      reloadKey: 0,
      loading: false,
      timerId: null,
      timer: 0,
    };
  },
  created() {},
  computed: {},
  methods: {
    reloadComponent(tabIndex) {
      this.reloadKey += 1; // Increment the reload key
      this.tab = tabIndex; // Change the active tab to trigger a re-render
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped>
/* Tab styles */
.tab-container {
  padding: 0px;
}
.tabs-content {
  margin-top: 10px;
  /* padding: 20px; */
  background-color: transparent !important;
  border-bottom: none !important;
}
.tabs-item-card {
  border: none;
  background-color: transparent !important;
}
.custom-tab {
  font-size: 14px !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
</style>
