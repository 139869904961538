<template>
  <div class="px-3">
    <v-overlay v-model="loading">
      <v-progress-circular
        indeterminate
        size="40"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <delete-selected
      v-show="selected_ids.length >= 2"
      :selected_ids="selected_ids"
    ></delete-selected>
    <v-row class="white rounded-xl px-5 font-weight-bold mb-2 text-left">
      <v-col cols="1">
        <v-checkbox
          v-model="select_all"
          class="pa-0 ma-0"
          @click="selectAll"
          id="select_all"
          style="position: absolute"
        ></v-checkbox>
      </v-col>
      <v-col cols="4">
        <div>
          <div>Title</div>
        </div>
      </v-col>
      <v-col cols="2">
        <div>Recorded by</div>
      </v-col>
      <v-col cols="2">
        <div>Label</div>
      </v-col>
      <v-col cols="2">
        <div>Recorded</div>
      </v-col>
      <v-col cols="1">
        <div>Actions</div>
      </v-col>
    </v-row>
    <div class="mt-6" v-for="(record, index) in recordings" :key="index">
      <v-row
        class="white rounded-xl px-5 font-weight-bold mb-2 text-left text-12"
      >
        <v-col cols="1" align-self="center" style="position: relative">
          <v-checkbox
            class="pa-0 ma-0"
            style="position: absolute; top: 0"
            :value="record.id"
            v-model="selected_ids"
          ></v-checkbox>
        </v-col>
        <v-col
          @click="showDetail(record)"
          cols="4"
          align-self="center"
          class="primary--text pointer"
        >
          <v-icon color="primary--text" class="grey--text lighten-3">
            mdi-play-circle-outline
          </v-icon>
          {{ record.title }}
        </v-col>
        <v-col cols="2" align-self="center">
          <div>{{ record["user"]["name"] }}</div>
        </v-col>
        <v-col cols="2" align-self="center">
          <v-chip class="grey lighten-3" small>
            {{ record["category"]["name"] }}
          </v-chip>
        </v-col>
        <v-col cols="2" align-self="center">
          <div class="pt-2">
            {{ record["date_recorded"] }}
          </div>
        </v-col>
        <v-col cols="1" class="k-folder-menu text-center">
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="primary--text"
                  class="black--text lighten-3 k-folder-menu-icon"
                >
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list class="k-folder-dropdown">
                <div class="k-folder-dropdown-item" @click="editDialog(record)">
                  Edit Message
                </div>
                <div
                  class="k-folder-dropdown-item"
                  @click="callShareRecording(record)"
                >
                  Share
                </div>
                <div
                  class="k-folder-dropdown-item"
                  @click="callMoveRecordingToFolder(record)"
                >
                  Move
                </div>
                <div
                  class="k-folder-dropdown-item"
                  @click="handleDuplicateRecording(record)"
                >
                  Duplicate
                </div>
                <div
                  class="k-folder-dropdown-item"
                  @click="callArchiveRecording(record)"
                >
                  Archive
                </div>
                <div
                  class="k-folder-dropdown-item"
                  @click="openDownloadingDialog(record)"
                >
                  Download
                </div>
                <div
                  class="k-folder-dropdown-item"
                  @click="deleteRecordingDialog(record)"
                >
                  Delete
                </div>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="!recordings.length">No record found!</div>
    <v-pagination
      v-model="pagination.page"
      :length="pagination.total"
      :total-visible="7"
      @input="nextPage()"
      v-if="
        $router['name'] !== 'Dashboard' &&
        recordings.length &&
        pagination.total > 1
      "
    ></v-pagination>
    <edit-recording-dialog ref="editDialog"></edit-recording-dialog>
    <delete-recording-dialog
      ref="deleteRecordingDialog"
    ></delete-recording-dialog>
    <share-recording-dialog ref="shareRecordingRef"></share-recording-dialog>
    <move-recording-to-folder-dialog
      ref="moveRecordingToFolderRef"
      @load-recordings="getDashboardMyRecordings"
    ></move-recording-to-folder-dialog>
    <archive-recording-dialog
      ref="archiveRecordingDialog"
      @load-recordings="getDashboardMyRecordings"
    ></archive-recording-dialog>
    <download-recording-dialog
      ref="openDownloadingRef"
    ></download-recording-dialog>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import ResponseHelper from "../utils/ResponseHelper";
import EditRecordingDialog from "./RecordDetails/EditRecordingDialog.vue";
import DeleteSelected from "./RecordDetails/DeleteSelected.vue";
import DeleteRecordingDialog from "./RecordDetails/DeleteRecordingDialog.vue";
import ShareRecordingDialog from "./RecordDetails/ShareRecordingDialog.vue";
import MoveRecordingToFolderDialog from "./RecordDetails/MoveRecordingToFolderDialog.vue";
import ArchiveRecordingDialog from "./RecordDetails/ArchiveRecordingDialog.vue";
import DownloadRecordingDialog from "./RecordDetails/DownloadRecordingDialog.vue";
export default {
  name: "DashboardMyRecordings",
  // props: {
  //   recordings: Array,
  // },
  components: {
    EditRecordingDialog,
    DeleteSelected,
    DeleteRecordingDialog,
    ShareRecordingDialog,
    MoveRecordingToFolderDialog,
    ArchiveRecordingDialog,
    DownloadRecordingDialog,
  },
  data() {
    return {
      recordings: [],
      toast: new ResponseHelper(),
      pagination: {
        page: 1,
        total: 1,
      },
      loading: false,
      search: "",
      timerId: null,
      timer: 0,
      item: {},
      select_all: false,
      selected_ids: [],
    };
  },
  created() {
    this.getDashboardMyRecordings();
    this.$root.$on("search", (data) => {
      this.timer = 1;
      this.timerId = setTimeout(() => {
        if (this.timer > 0) {
          this.search = data;
          this.pagination.page = 1;
          this.getDashboardMyRecordings();
        }
        this.timer = 0;
      }, 2000);
    });
  },
  // updated() {
  //   this.recordings = this.dashboardMyRecordingDetails;
  // },
  watch: {
    dashboardMyRecordingDetails: {
      handler(newRecord) {
        this.recordings = newRecord;
      },
    },
  },
  computed: {
    ...mapGetters("recordings", ["dashboardMyRecordingDetails"]),
  },
  methods: {
    ...mapMutations("recordings", ["SHOW_DETAILS"]),
    ...mapActions("recordings", [
      "dashboard_my_recordings",
      "duplicate_recording",
    ]),

    async getDashboardMyRecordings(page) {
      if (!page) {
        page = "?page=" + this.pagination.page + "&search=" + this.search;
      }
      this.loading = true;
      try {
        const response = await this.dashboard_my_recordings(page);
        this.recordings = response.data.data;
        this.pagination.page = response.data["current_page"];
        this.pagination.total = response.data["last_page"];
        this.selected_ids = [];
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
        console.log("Error:", e);
      }
    },
    async handleDuplicateRecording(record) {
      this.loading = true;
      try {
        const response = await this.duplicate_recording(record);
        this.loading = false;
        this.toast.sendSuccess(response);
        // Hot load datatable
        window.location.reload();
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    showDetail(item) {
      this.SHOW_DETAILS(item);
      this.$router.push({
        name: "MyRecordingDetails",
        params: { id: item.id },
      });
    },
    nextPage() {
      this.recordings = [];
      let page = "?page=" + this.pagination.page + "&search=" + this.search;
      this.getDashboardMyRecordings(page);
      if (this.select_all) {
        document.getElementById("select_all").click();
      }
      this.selectAll();
    },
    deleteRecordingDialog(record) {
      this.$refs.deleteRecordingDialog.openDialog(record);
    },
    editDialog(record) {
      this.$refs.editDialog.openDialog(record);
    },
    callShareRecording(record) {
      this.$refs.shareRecordingRef.openDialog(record);
    },
    callMoveRecordingToFolder(record) {
      this.$refs.moveRecordingToFolderRef.openDialog(record);
    },
    callArchiveRecording(record) {
      this.$refs.archiveRecordingDialog.openDialog(record);
    },
    openDownloadingDialog(record) {
      this.$refs.openDownloadingRef.openDialog(record);
    },
    selectAll() {
      this.selected_ids = [];
      if (this.select_all) {
        this.recordings.forEach((row) => {
          this.selected_ids.push(row.id);
        });
      } else {
        this.selected_ids = [];
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped>
.k-folder-menu {
  padding: 0px;
  padding-top: 10px;
}
.k-folder-menu-icon {
  border-radius: 50%;
  padding: 2px;
}
.k-folder-menu-icon:hover {
  background-color: #ddd;
  color: #000 !important;
}
.k-folder-dropdown {
  width: 150px !important;
  text-align: left !important;
  padding: 10px 10px;
}
.k-folder-dropdown-item {
  padding: 0px 13px;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 600;
}
.k-folder-dropdown > .k-folder-dropdown-item {
  cursor: pointer;
  border-radius: 50px;
  height: 30px !important;
  line-height: 25px;
  border: 1px solid #fff !important;
}
.k-folder-dropdown > .k-folder-dropdown-item:hover {
  background-color: #dbdbdb;
  border: 1px solid #ddd !important;
}
</style>
